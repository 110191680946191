/* eslint-disable unicorn/no-null */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessToken, selectPlayerAttributes } from '../../../store/selectors';
import { getPlayerAttributes } from '../../../store/player-attributes/get-player-attributes';
import { DataTable } from 'primereact/datatable';
import { ProgressSpinner } from 'primereact/progressspinner';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { formatDateTime, makeDate } from '../../../utilities/formatting';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { PAGINATOR_ROWS, PAGINATOR_ROWS_PER_PAGE, PAGINATOR_TEMPLATE } from '../../constants/paginator-settings';
import dateFilterTemplate from '../../shared/date-filter-template';
import publicationFilterTemplate from '../../shared/publication-filter-template';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const enabledBodyTemplate = (playerAttribute: any) => {
  return <Checkbox checked={playerAttribute.isEnabled} disabled></Checkbox>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const checkBoxFilterTemplate = (options: any) => {
  return <TriStateCheckbox value={options.value} onChange={(event) => options.filterCallback(event.value)} />;
};

const filters = {
  accommodationCode: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
  },
  publicationCode: {
    value: null,
    matchMode: FilterMatchMode.IN,
  },
  remark: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
  },
  createdDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
  modifiedDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
  isEnabled: { value: null, matchMode: FilterMatchMode.EQUALS },
};

function PlayerAttributesList() {
  const dispatch = useDispatch();
  const playerAttributes = useSelector(selectPlayerAttributes).map((playerAttribute) => ({
    ...playerAttribute,
    createdDate: makeDate(playerAttribute.createdDate),
    modifiedDate: makeDate(playerAttribute.modifiedDate),
  }));
  const accessToken = useSelector(selectAccessToken);

  useEffect(() => {
    if (playerAttributes.length === 0 && accessToken) {
      dispatch(getPlayerAttributes());
    }
  }, [accessToken]);


  return (
    <>
      {playerAttributes.length === 0 ? (
        <ProgressSpinner />
      ) : (
        <DataTable
          paginator
          filters={filters}
          sortField="accommodationCode"
          sortOrder={1}
          rowsPerPageOptions={PAGINATOR_ROWS_PER_PAGE}
          rows={PAGINATOR_ROWS}
          value={playerAttributes}
          currentPageReportTemplate="Total: {totalRecords}"
          paginatorTemplate={PAGINATOR_TEMPLATE}
        >
          <Column field="accommodationCode" sortable filter header="Accommodation"></Column>
          <Column
            field="publicationCode"
            filterElement={(entry) => publicationFilterTemplate(entry, playerAttributes)}
            showFilterMatchModes={false}
            sortable
            filter
            header="Publication"
          ></Column>
          <Column field="remark" sortable filter header="Remark"></Column>
          <Column
            field="createdDate"
            sortable
            filter
            dataType="date"
            header="Created Date"
            filterElement={dateFilterTemplate}
            body={(entry) => formatDateTime(entry.createdDate)}
          ></Column>
          <Column
            field="modifiedDate"
            sortable
            dataType="date"
            header="Modified Date"
            body={(entry) => formatDateTime(entry.modifiedDate)}
          ></Column>
          <Column
            field="isEnabled"
            sortable
            dataType="boolean"
            filter
            filterElement={checkBoxFilterTemplate}
            header="Enabled"
            body={enabledBodyTemplate}
          ></Column>
        </DataTable>
      )}
    </>
  );
}

export default PlayerAttributesList;
